import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import GroupScrapper from "./GroupScrapper";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import Contacts from "./Contacts";
import Settings from "./Settings";
import TextEditor from "./TextEditor/TextEditor";
import Attachment from "./Attachment";
import { CONSTANTS } from "../Constants";
import MessagesStatus from "./MessagesStatus";
import { filterKeys, parseEditorState, validateSession } from "../helper";
import { Alert, Snackbar } from "@mui/material";
const socket = io.connect("https://whatsapp.pentesthouse.com:9008", {secure: true});

const Whatsapp = () => {
  const [openWhatsAppDialog, setOpenWhatsAppDialog] = useState(false);
  const [openGroupScrapper, setOpenGroupScrapper] = useState(false);
  const [openMessageStatus, setOpenMessageStatus] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [id, setId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("Disconnected");
  const [loginStatus, setLoginStatus] = useState("Logged Out");
  const [contacts, setContacts] = useState({});
  const [showSettings, setShowSettings] = useState(false);
  const [settings, setSettings] = useState(CONSTANTS.INTITIAL_SETTINGS_STATE);
  const [messages, setMessages] = useState([]);
  const [messageStatus, setMessageStatus] = useState([]);
  const [contactDetails, setContactDetails] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [currentMessage, setCurrentMessage] = useState({
    contact: "",
  });

  const [messageSentIndex, setMessageSentIndex] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [sendMessagesDetails, setSendMessagesDetails] = useState([]);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [
    showSendMessageConfirmationPopup,
    setShowSendMessageConfirmationPopup,
  ] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const isPausedRef = useRef(isPaused);
  const stopRef = useRef(false);

  useEffect(() => {
    const handleReady = (data) => {
      const { id } = data;
      if (validateSession(id)) {
        setOpenWhatsAppDialog(false);
        setIsLoading(false);
        setId(id);
        setQrCode("");
        setStatus("Connected");
        setLoginStatus("Logged In");
      }
    };

    const handleLogout = (data) => {
      const { id } = data;
      if (validateSession(id)) {
        setId("");
        setQrCode("");
        setStatus("Disconnected");
        setLoginStatus("Logged Out");
        localStorage.removeItem("session");
      }
    };

    const handleDisconnected = (data) => {
      const { id } = data;
      if (validateSession(id)) {
        setId("");
        setQrCode("");
        setStatus("Disconnected");
        // setLoginStatus("Logged Out");
      }
    };

    const handleLoadingScreen = (data) => {
      const { id } = data;
      if (validateSession(id)) {
        setIsLoading(true);
      }
    };

    const handleError = (data) => {
      if (data.type === "session_err" || data.type === "client_err") {
        localStorage.removeItem("session");
        setOpenWhatsAppDialog(false);
        setQrCode("");
        setOpenGroupScrapper(false);
        setIsLoading(false);
        setId("");
        setLoginStatus("Logged Out");
        setStatus("Disconnected");
        setSnackbarMessage("Something went wrong. Please try again!");
        setSnackbarOpen(true);
        createSessionForWhatsapp();
      }
    };

    const handleValidContatcs = (data) => {
      const { id } = data;
      if (validateSession(id)) {
        setContacts(data.contacts);
      }
    };

    const handleMessageSent = (data) => {
      const { id } = data;
      if (validateSession(id)) {
        if (messageSentIndex + 1 === totalMessages) {
          setSendMessagesDetails([]);
          setMessageSentIndex(0);
        } else {
          setMessageSentIndex((prevState) => prevState + 1);
        }
        setMessageStatus((prevState) => [...prevState, data]);
      }
    };

    socket.on("ready", handleReady);
    socket.on("logout", handleLogout);
    socket.on("disconnected", handleDisconnected);
    socket.on("loading_screen", handleLoadingScreen);
    socket.on("message-sent", handleMessageSent);
    socket.on("contact-details", handleContactDetails);
    socket.on("validate-numbers", handleValidContatcs);
    socket.on("error", handleError);

    // Cleanup on unmount
    return () => {
      socket.off("ready", handleReady);
      socket.off("logout", handleLogout);
      socket.off("disconnected", handleDisconnected);
      socket.off("loading_screen", handleLoadingScreen);
      socket.off("message-sent", handleMessageSent);
      socket.off("contact-details", handleContactDetails);
      socket.off("error", handleError);
    };
  }, []);

  useEffect(() => {
    let newContacts = [];
    Object.keys(contacts).map((contact) => {
      if (!contactDetails[contact]) {
        newContacts.push(contact);
      }
    });
    if (newContacts.length)
      socket.emit("get-contact-details", {
        id,
        contacts: newContacts,
      });
  }, [contacts]);

  useEffect(() => {
    isPausedRef.current = isPaused;
  }, [isPaused]);

  const createSessionForWhatsapp = () => {
    let session = localStorage.getItem("session");
    if (!session) {
      session = uuidv4();
      localStorage.setItem("session", session);
    }
    socket.emit("createSession", {
      id: session,
    });
  };

  const handleMessageChange = (editorState) => {
    let { plainText, htmlContent } = parseEditorState(editorState);
    let newMessage = messages.filter((message) => message.type === "media");
    if (plainText.trim()) {
      newMessage.unshift({ content: htmlContent, type: "text" });
    }
    setMessages(newMessage);
  };

  const handleOpenWhatsappSession = async () => {
    createSessionForWhatsapp();
    await socket.on("qr", (data) => {
      const { qr } = data;
      console.log("QR RECEIVED", qr);
      setIsLoading(false);
      setQrCode(qr);
    });
    setOpenWhatsAppDialog(true);
  };

  const handlecloseWhatsappSession = async () => {
    socket.emit("logout", {
      id,
    });
  };

  const handleContactDetails = (data) => {
    setContactDetails((prevDetails) => ({
      ...prevDetails,
      ...data.contactDetails,
    }));
  };

  const closeWhatsAppDialog = async () => {
    setOpenWhatsAppDialog(false);
  };

  const addToSendersList = (contactList) => {
    setContacts({ ...contacts, ...contactList });
  };

  const handleValidateNumbers = () => {
    socket.emit("validate-numbers", { id, contacts });
  };

  const sendMessage = async (id, messageData) => {
    if (isPausedRef.current) {
      await checkPauseAndDelay(0); // wait until resumed
    }
    if (stopRef.current) {
      return;
    }
    try {
      socket.emit("sendMessage", {
        id,
        contact: messageData.contact,
        message: messageData.message,
        contactDetails: contactDetails[messageData.contact],
      });
      // return { status: "sent", date: new Date() };
    } catch (error) {
      console.error(
        `Failed to send message to ${messageData.contact.id}: ${error}`
      );
      // return { status: "failed", date: new Date() };
    }
  };

  const checkPauseAndDelay = async (delay) => {
    if (!stopRef.current) {
      while (isPausedRef.current) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      await new Promise((resolve) => setTimeout(resolve, delay * 1000));
    }
  };

  const startSendingMessages = async (messageData) => {
    setOpenMessageStatus(true);
    stopRef.current = false;
    let messageCount = 0;
    let index = messageSentIndex;

    for (const message of messageData) {
      if (stopRef.current) break;

      const delay =
        index === 0
          ? 0
          : Math.floor(
              Math.random() *
                (parseInt(settings.waitBetweenEnd) -
                  parseInt(settings.waitBetweenStart)) +
                parseInt(settings.waitBetweenStart)
            );

      setCurrentMessage({
        contact: message.contact,
      });
      await checkPauseAndDelay(delay);

      await sendMessage(id, message);

      messageCount++;
      index = index + 1;

      if (messageCount >= parseInt(settings.sleepAfterMessages)) {
        await checkPauseAndDelay(parseInt(settings.sleepFor));
        messageCount = 0;
      }
    }
  };

  const handleMessageStatusClose = () => {
    if (isPausedRef.current || messageSentIndex === totalMessages) {
      stopRef.current = true;
      setIsPaused(false);
      setOpenMessageStatus(false);
      setMessageStatus([]);
      setCurrentMessage({ contact: "" });
      setSendMessagesDetails([]);
      setMessageSentIndex(0);
    } else {
      if (messageSentIndex !== totalMessages) {
        setShowConfirmationPopup(true);
      }
    }
  };

  const handleSendMessageClick = async () => {
    if (sendMessagesDetails.length) {
      setShowSendMessageConfirmationPopup(true);
    } else {
      let newContacts = Object.keys(contacts);
      let newDetails = [];
      newContacts.forEach((contact) => {
        messages.forEach((message) => {
          newDetails.push({ contact, message });
        });
      });
      setMessageStatus([]);
      setOpenMessageStatus(true);
      setSendMessagesDetails(newDetails);
      setTotalMessages(newDetails.length);
      startSendingMessages(newDetails);
      setMessageSentIndex(0);
    }
  };

  const handleConfirmClose = () => {
    let newContacts = Object.keys(contacts);
    let newDetails = [];

    newContacts.forEach((contact) => {
      messages.forEach((message) => {
        newDetails.push({ contact, message });
      });
    });

    let allData = [...sendMessagesDetails, ...newDetails];
    setTotalMessages(allData.length);
    setSendMessagesDetails(allData);
    startSendingMessages(newDetails);
    setShowSendMessageConfirmationPopup(false);
  };

  const handleCancelClose = () => {
    setShowSendMessageConfirmationPopup(false);
  };

  const handleTerminateProcess = () => {
    stopRef.current = true;
    setIsPaused(false);
    setOpenMessageStatus(false);
    setMessageStatus([]);
    setMessageSentIndex(0);
    setCurrentMessage({ contact: "" });
    setSendMessagesDetails([]);
    setShowConfirmationPopup(false);
  };

  const handleContinueProcess = () => {
    setShowConfirmationPopup(false);
    setOpenMessageStatus(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Dialog open={openWhatsAppDialog} onClose={closeWhatsAppDialog}>
        <div style={{ display: "flex" }}>
          <DialogTitle>Open Whatsapp and Scan Qr Code</DialogTitle>
          <IconButton aria-label="close" onClick={closeWhatsAppDialog}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          {!qrCode || isLoading ? (
            <CircularProgress />
          ) : (
            <QRCode value={qrCode} />
          )}
        </DialogContent>
      </Dialog>
      <Button
        variant="contained"
        disabled={!!id}
        onClick={handleOpenWhatsappSession}
      >
        Open Whatsapp
      </Button>
      <Button
        variant="contained"
        onClick={() => setShowSettings(true)}
        startIcon={<SettingsIcon />}
      >
        Settings
      </Button>
      <Button disabled={!id} onClick={handlecloseWhatsappSession}>
        Log Out
      </Button>
      {id && (
        <div>
          <div>
            <Button onClick={() => setOpenGroupScrapper(true)}>
              Multi Group Scrapper
            </Button>
          </div>
          <div style={{ display: "flex" }}>
            <Contacts
              contacts={contacts}
              setContacts={setContacts}
              handleValidateNumbers={handleValidateNumbers}
            />
            <div>
              <TextEditor handleChange={handleMessageChange} />
              <Attachment messages={messages} setMessages={setMessages} />
              <Button
                disabled={
                  !Object.keys(contacts).length ||
                  !messages.length ||
                  status === "Disconnected"
                }
                onClick={() => handleSendMessageClick(false)}
              >
                Send
              </Button>
              <Button
                style={{
                  display:
                    !messageSentIndex || messageSentIndex === totalMessages
                      ? "none"
                      : "block",
                }}
                onClick={() => setOpenMessageStatus(true)}
              >
                {messageSentIndex + "/ " + totalMessages}
              </Button>
            </div>
          </div>
        </div>
      )}
      <div>
        <Chip label={status} />/ <Chip label={loginStatus} />
      </div>
      <Dialog open={showSettings} onClose={() => setShowSettings(false)}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>Settings</DialogTitle>
          <IconButton aria-label="close" onClick={() => setShowSettings(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <Settings settings={settings} setSettings={setSettings} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openGroupScrapper}
        onClose={() => setOpenGroupScrapper(false)}
        PaperProps={{
          sx: {
            maxWidth: "88%",
          },
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>Group Scrapper</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpenGroupScrapper(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <GroupScrapper
            socket={socket}
            id={id}
            addToSendersList={addToSendersList}
            setOpenGroupScrapper={setOpenGroupScrapper}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openMessageStatus}
        onClose={handleMessageStatusClose}
        PaperProps={{
          sx: {
            maxWidth: "88%",
          },
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>Sending Whatsapp</DialogTitle>
          <IconButton aria-label="close" onClick={handleMessageStatusClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <MessagesStatus
            messageStatus={messageStatus}
            handlePause={() => setIsPaused((prev) => !prev)}
            handleClose={handleMessageStatusClose}
            handleConfirmClose={handleTerminateProcess}
            handleCancelClose={handleContinueProcess}
            showConfirmationPopup={showConfirmationPopup}
            isPaused={isPaused}
            totalMessages={totalMessages}
            currentMessage={currentMessage}
            messageSentIndex={messageSentIndex}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={showSendMessageConfirmationPopup}
        onClose={() => setShowSendMessageConfirmationPopup(false)}
      >
        <DialogTitle>Messages</DialogTitle>{" "}
        <IconButton
          aria-label="close"
          onClick={() => setShowSendMessageConfirmationPopup(false)}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div>
            Messages are still being sent. Do you want to continue sending this
            message after the current process ends?
          </div>
          <Button onClick={handleConfirmClose}>Yes</Button>
          <Button onClick={handleCancelClose}>No</Button>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Whatsapp;
