import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import CircularProgress from "@mui/material/CircularProgress";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as XLSX from "xlsx";
import { s2ab, validateSession } from "../helper";

const GroupScrapper = (props) => {
  const { socket, id, addToSendersList, setOpenGroupScrapper } = props;
  const [groupChats, setGroupChats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allChats, setAllChats] = useState([]);
  const [groupChatsWithId, setGroupChatsWithId] = useState([]);
  const [contactGroupList, setContactGroupList] = useState([]);
  const [allContactlength, setAllContactlength] = useState(0);
  const [selected, setSelected] = useState([]);
  const [contactObject, setContactObject] = useState([]);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [exportType, setExportType] = useState("");
  const [exportFormat, setExportFormat] = useState("Text");
  const [exportSelectedRows, setExportSelectedRows] = useState(false);
  const [showExportPopup, setShowExportPopup] = useState(false);

  useEffect(() => {
    if (allChats.length) {
      setValues(allChats);
    }
  }, [allChats]);

  useEffect(() => {
    const handleAllChats = (data) => {
      if (validateSession(id)) {
        setIsLoading(false);
        setAllChats(data.allChats);
      }
    };

    socket.on("allChats", handleAllChats);

    return () => {
      socket.off("allChats", handleAllChats);
    };
  }, []);

  const setValues = (dataChats) => {
    const filteredChats = dataChats.filter(
      (chat) =>
        chat.isGroup &&
        !chat.groupMetadata?.isParentGroup &&
        !chat.groupMetadata?.parentGroup
    );
    setGroupChats(filteredChats);
    let chats = {};
    filteredChats.forEach((chat) => {
      chats[chat.id._serialized] = chat;
    });
    setGroupChatsWithId(chats);
  };

  const headCells = [
    { id: "name", label: "Group Name" },
    { id: "id", label: "Group ID" },
  ];

  const handleGroupScraping = () => {
    socket.emit("getAllChats", { id });
    setIsLoading(true);
  };

  const createData = () => {
    return groupChats.map((chat) => {
      return {
        id: chat.id._serialized,
        name: chat.name,
      };
    });
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - groupChats.length) : 0;

  const visibleRows = useMemo(
    () => createData()
    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    // [page, rowsPerPage, groupChats]
  );

  const handleSelectAllClick = (event) => {
    if (event) {
      const newSelected = createData().map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const EnhancedTableHead = (props) => {
    // const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {/* <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            /> */}
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              padding="normal"
              sortDirection={false}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const getContacts = () => {
    let contactObj = {};
    let contacts = [];
    selected.forEach((id) => {
      groupChatsWithId[id].groupMetadata.participants.forEach((user) => {
        contacts.push(user.id.user);
        contactObj[user.id.user] = user.name || "N/A";
      });
    });
    setContactObject(contactObj);
    setAllContactlength(contacts.length);
    setContactGroupList([...new Set(contacts)]);
  };

  const handleClearGroup = () => {
    setGroupChats([]);
    setGroupChatsWithId([]);
    setContactGroupList([]);
    setAllContactlength(0);
    // setPage(0);
    setSelected([]);
  };

  const handleClearContacts = () => {
    setContactGroupList([]);
    setAllContactlength(0);
  };

  const handleClickExport = (type) => {
    setExportType(type);
    setShowExportPopup(true);
  };

  const handleExportAsExcel = (data, filename, type) => {
    const worksheet = XLSX.utils[type || "aoa_to_sheet"](data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contacts");
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Create a download link
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename + ".xlsx";
    document.body.appendChild(a);
    a.click();

    // Clean up and revoke the object URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleExportAsText = (data, filename) => {
    const fileContent = data.join("\n");
    const blob = new Blob([fileContent], { type: "text/plain" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleExport = () => {
    if (exportFormat === "Excel") {
      if (exportType === "Group") {
        let data = exportSelectedRows
          ? selected.map((id) => {
              return {
                "Group Name": groupChatsWithId[id].name,
                "Group ID": groupChatsWithId[id].id._serialized,
              };
            })
          : groupChats.map((data) => ({
              "Group Name": data.name,
              "Group ID": data.id._serialized,
            }));
        handleExportAsExcel(data, "Group Data", "json_to_sheet");
      } else {
        handleExportAsExcel(
          contactGroupList.map((contact) => [contact]),
          "Contacts"
        );
      }
    } else {
      if (exportType === "Group") {
        let data = exportSelectedRows
          ? selected.map(
              (id) =>
                `${groupChatsWithId[id].name}, ${groupChatsWithId[id].id._serialized}`
            )
          : createData().map((group) => `${group.name}, ${group.id}`);
        handleExportAsText(data, "group data.txt");
      } else {
        handleExportAsText(contactGroupList, "contacts.txt");
      }
    }
  };

  const handleAddToSenderList = () => {
    addToSendersList(contactObject);
    setOpenGroupScrapper(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <Button onClick={handleGroupScraping}>
          Download all my joined groups list
        </Button>
        {isLoading ? (
          <div style={{ height: "250px" }}>
            <CircularProgress />
          </div>
        ) : (
          <TableContainer
            sx={{ height: 250, overflow: "auto", maxWidth: "530px" }}
          >
            <Table aria-labelledby="tableTitle" size="small">
              <EnhancedTableHead
                numSelected={selected.length}
                // onSelectAllClick={handleSelectAllClick}
                rowCount={groupChats.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell id={labelId}>{row.name}</TableCell>
                      <TableCell>{row.id}</TableCell>
                    </TableRow>
                  );
                })}
                {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
              </TableBody>
            </Table>
            {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={groupChats.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          </TableContainer>
        )}
        <Chip label={`group count(${groupChats.length})`} />
        <Button onClick={() => handleSelectAllClick(true)}>Select All</Button>
        <Button onClick={() => handleSelectAllClick(false)}>
          Deselect All
        </Button>
        <Button onClick={handleClearGroup}>Clear</Button>
        <Button
          onClick={() => handleClickExport("Group")}
          disabled={!groupChats.length}
        >
          Export
        </Button>
      </div>
      <div>
        <Button onClick={getContacts}>Get Contacts from selected groups</Button>
        <Box height={250} overflow={"auto"}>
          <List>
            {contactGroupList.map((item) => (
              <ListItem>
                <ListItemText primary={item} key={item} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Chip label={`contact count(${contactGroupList.length})`} />
        <Chip
          label={`duplicates(${allContactlength - contactGroupList.length})`}
        />
        <Button onClick={handleClearContacts}>Clear</Button>

        <Button
          onClick={() => handleClickExport("Contact")}
          disabled={!contactGroupList.length}
        >
          Export
        </Button>

        <Button onClick={handleAddToSenderList}>Add to senders list</Button>

        <Dialog
          open={showExportPopup}
          onClose={() => setShowExportPopup(false)}
        >
          <DialogTitle>Export {exportType} Data</DialogTitle>{" "}
          <IconButton
            aria-label="close"
            onClick={() => setShowExportPopup(false)}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div>
              <InputLabel>Export As</InputLabel>
              <Select
                value={exportFormat}
                label="Export as"
                onChange={(e) => setExportFormat(e.target.value)}
              >
                <MenuItem value={"Excel"}>Excel</MenuItem>
                <MenuItem value={"Text"}>Text</MenuItem>
              </Select>
            </div>
            {exportType !== "Contact" && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={exportSelectedRows}
                      onChange={(e) => setExportSelectedRows(e.target.checked)}
                    />
                  }
                  label="Export only selected rows"
                />
              </div>
            )}

            <Button
              onClick={handleExport}
              disabled={exportSelectedRows && !selected.length}
            >
              Export
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default GroupScrapper;
